.Wrapper{
 margin-left: 10%;
}
.container{
  display: flex;
  max-width: 800px;
  flex-direction: row;
  
  justify-content: center;
  
  margin: 50px;
}
.header{
  font-weight: 600;
}

.logo{
  width: 20%;
}

.body{
  max-width: 800px;
}

.signature{
  max-width: 300px;
  margin-left: -80px;
}